<template>
  <div class="jw-room-member" :class="{ 'has-title': roomMembers.title }">
    <div class="jw-room-member__thumbs" :class="`jw-room-member__${memCntClass}`">
      <template v-if="roomMembers.memCnt > 1">
        <v-avatar
          v-for="(item, index) in profileObj"
          :key="'avatar-' + index"
          class="jw-room-member__avatar"
          :size="thumbSize"
          color="#C2E3D0"
        >
          <!-- <VImg v-if="!!item.imgSrc && !item.isBlock" :src="item.imgSrc" /> -->
          <VImg v-if="!!item.img && item.kind == 'url'" :src="item.img" />
          <span v-else-if="item.kind == 'string'" class="initial">
            {{ item.img }}
          </span>
        </v-avatar>
      </template>
      <v-avatar v-else class="jw-room-member__avatar" :size="thumbSize" color="#C2E3D0">
        <VImg v-if="profileObj.kind == 'url'" :src="profileObj.img" />
        <span v-else-if="profileObj.kind == 'string'" class="initial">
          {{ profileObj.img }}
        </span>
      </v-avatar>
    </div>

    <div v-if="roomMembers.title" class="jw-room-member__title">{{ roomMembers.title }}</div>
  </div>
</template>

<script setup>
import { displayName } from "~/utils/utils";
const props = defineProps({
  roomMembers: {
    type: Object,
    default: () => {
      return {
        members: [
          {
            imgSrc: "", // 멤버 사진
            isBlock: false, // 멤버 차단 여부
          },
        ],
        title: "", // 하단 제목,
        profilePhoto,
        memCnt,
      };
    },
  },
});
//const memberNums = props.roomMembers.members.length;

const memCntClass = computed(() => {
  if (props.roomMembers.memCnt < 4) {
    return props.roomMembers.memCnt;
  } else if (props.roomMembers.memCnt >= 4) {
    return "4";
  }
});
const baseImgUrl = `https://jwj.kr/upload/member`;
const profileObj = computed(() => {
  if (Number(props.roomMembers.memCnt) < 2) {
    // 멤버가 1명일 경우엔 profilePhoto가 string형태의 숫자인지만 확인하면 된다.
    if (isNaN(props.roomMembers.profilePhoto)) {
      return { kind: "string", img: props.roomMembers.profilePhoto };
    } else {
      return { kind: "url", img: `${baseImgUrl}/${props.roomMembers.profilePhoto}` };
    }
  } else {
    // 멤버가 2명 이상일 경우
    const list = props.roomMembers.profilePhoto.split(",").map((itm) => {
      if (isNaN(itm)) {
        if (itm == "none") return { kind: "url", img: null };
        return { kind: "string", img: displayName(itm) };
      } else {
        return { kind: "url", img: `${baseImgUrl}/${itm}` };
      }
    });

    return list;
  }
});

const memberNums = Number(props.roomMembers.memCnt);
const thumbSize = computed(() => {
  return memberNums === 1 ? 50 : memberNums === 2 ? 34 : 30;
});

const members = computed(() => {
  return memberNums > 4 ? props.roomMembers.members.slice(0, 4) : props.roomMembers.members;
});
</script>
